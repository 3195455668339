import { render, staticRenderFns } from "./RoundCheckbox.vue?vue&type=template&id=4dafc7f6&scoped=true&"
import script from "./RoundCheckbox.vue?vue&type=script&lang=js&"
export * from "./RoundCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./RoundCheckbox.vue?vue&type=style&index=0&id=4dafc7f6&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dafc7f6",
  null
  
)

export default component.exports