<template>
  <div class="round">
    <input
      class="no-message"
      type="checkbox"
      :id="'checkbox-'+checkboxKey"
      :checked="value"
      @change="$emit('change')"
    />
    <label :data-letter="text" :for="'checkbox-'+checkboxKey" :class="{'error-days': error}"></label>
  </div>
</template>

<script>
export default {
  name: 'RoundCheckbox',
  props: {
    text: String,
    value: Boolean,
    checkboxKey: String,
    error: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped lang="sass">

  .error-days
    border: 1px solid red !important

  .round
    position: relative

    label
      background-color: #e0e0e0
      border: 1px solid #ccc
      border-radius: 50%
      cursor: pointer
      height: 28px
      left: 0
      position: absolute
      top: 0
      width: 28px
      &:hover
        background-color: var(--v-primary-lighten2)


      &:after
        content: attr(data-letter)
        position: absolute
        left: 50%
        top: 50%
        font-size: 14px
        transform: translate(-50%, -50%)

    input[type="checkbox"]
      visibility: hidden

      &:checked + label
        background-color: var(--v-primary-base)
        border-color: transparent

      &:checked + label:after
        opacity: 1
        color: #FFFFFF
</style>
